
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import fetchApi from "@/api";
import MainCard from "@/components/card/MainCard.vue";
import PageHeader from "@/components/PageHeader.vue";
import IconCard from "@/components/card/IconCard.vue";
import { GatewayItem, MarkItem } from "./type";
import { getDeviceSomeInfo } from "@/utils";

type Num = {
  myApprove: number;
  myApplyNum: number;
};
export default defineComponent({
  components: { MainCard, IconCard, PageHeader },
  setup() {
    const { safeTop } = getDeviceSomeInfo();
    const paddingTop = Math.max(safeTop - 46, 0) + 44;
    const dataSource = ref<GatewayItem[]>([]);
    const colNum = ref(3);
    const num = ref<Num>({ myApprove: 0, myApplyNum: 0 });
    const loading = ref(false);
    const cardLoading = ref(true);
    const router = useRouter();

    const getDataSource = () => {
      dataSource.value = [
        {
          title: "休假申请",
          deepLink: "jdme://jm/biz/appcenter/process/vacation",
          icon: "https://storage.jd.com/cho-fe/imgs/process_xiujiashenqing.png",
        },
        {
          title: "考勤异常申请",
          deepLink: "jdme://jm/biz/appcenter/process/attendance",
          icon: "https://storage.jd.com/cho-fe/imgs/process_kaoqinyichang.png",
        },
        {
          title: "加班申请",
          deepLink: "jdme://jm/biz/appcenter/process/overtime",
          icon: "https://storage.jd.com/cho-fe/imgs/process_jiabanshenqing.png",
        },
      ];
    };
    const getColumnsNum = () => {
      colNum.value = document.documentElement.clientWidth > 480 ? 4 : 3;
    };
    onMounted(() => {
      nextTick(() => {
        getColumnsNum();
        getDataSource();

        fetchApi
          .getMarkProcessList()
          .data({ mobile: 1 })
          .send("POST")
          .then((res) => {
            cardLoading.value = false;
            if (res.data && res.data.length > 0) {
              dataSource.value = [
                ...dataSource.value,
                ...res.data.map((item: MarkItem) => ({
                  title: item.processDefinitionName,
                  deepLink: item.mobileApplyLink,
                })),
              ];
            }
          })
          .catch(() => {
            cardLoading.value = false;
          });
      });

      loading.value = true;
      fetchApi
        .getTodoAndApplyNum()
        .send("GET")
        .then((res: { data: Num }) => {
          loading.value = false;
          num.value = res.data;
        })
        .catch(() => {
          loading.value = false;
        });
    });
    return {
      dataSource,
      colNum,
      num,
      loading,
      router,
      cardLoading,
      paddingTop,
    };
  },
});
