import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "process-comps-iconcard-img" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "process-comps-iconcard-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "process-comps-iconcard",
    onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.touchstart && _ctx.touchstart(...args))),
    onTouchmove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchmove && _ctx.touchmove(...args))),
    onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.touchend && _ctx.touchend(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.url)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.url,
            alt: _ctx.title
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "image-replace",
            style: _normalizeStyle({
          backgroundImage: `linear-gradient(to right bottom, ${_ctx.someColors[_ctx.colorIndex][0]}, ${_ctx.someColors[_ctx.colorIndex][1]})`,
        })
          }, _toDisplayString(_ctx.iconText), 5))
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
  ], 32)), [
    [_directive_ripple]
  ])
}