import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_MainCard = _resolveComponent("MainCard")!
  const _component_u_card = _resolveComponent("u-card")!
  const _component_IconCard = _resolveComponent("IconCard")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_base_loading = _resolveComponent("base-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader, {
      title: "流程中心",
      isFixed: true
    }),
    _createElementVNode("div", {
      class: "process-center-wrapper touch-scroll",
      style: _normalizeStyle({
      height: `calc(100vh - ${_ctx.paddingTop + 10 + 'px'})`,
    })
    }, [
      _createVNode(_component_u_card, {
        title: "我的流程",
        class: "process-wrapper-maincard",
        bodyStyle: "padding: 12px 18px 20px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MainCard, {
            title: "我的审批",
            icon: "icon-shenpi",
            to: "approve",
            num: _ctx.num.myApprove,
            loading: _ctx.loading,
            style: {"margin-right":"12px","background-image":"linear-gradient(to right, #b28eeb, #8171eb)"}
          }, null, 8, ["num", "loading"]),
          _createVNode(_component_MainCard, {
            clstag: "h|keycount|home_1648196201250|1",
            title: "我的申请",
            icon: "icon-dingdanzhongxin",
            to: "apply",
            num: _ctx.num.myApplyNum,
            loading: _ctx.loading,
            style: {"background-image":"linear-gradient(to right, #8cc2fc, #3e96fa)"}
          }, null, 8, ["num", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_component_u_card, {
        title: "快速申请",
        class: "process-wrapper-gateway"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(_ctx.dataSource.length / _ctx.colNum), (col) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "process-wrapper-gateway-row",
              key: col
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource.slice(
            (col - 1) * _ctx.colNum,
            col * _ctx.colNum
          ), ({ deepLink, title, icon }, i) => {
                return (_openBlock(), _createBlock(_component_IconCard, {
                  key: i,
                  deepLink: deepLink,
                  url: icon,
                  title: title,
                  style: _normalizeStyle(`width: calc(100% / ${_ctx.colNum})`)
                }, null, 8, ["deepLink", "url", "title", "style"]))
              }), 128))
            ]))
          }), 128)),
          _createVNode(_component_van_button, {
            style: {"width":"calc(100% - 36px)","margin":"16px"},
            color: "var(--van-danger-color)",
            plain: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('processList')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("更多流程申请")
            ]),
            _: 1
          }),
          _createVNode(_component_base_loading, { loading: _ctx.cardLoading }, null, 8, ["loading"])
        ]),
        _: 1
      })
    ], 4)
  ], 64))
}