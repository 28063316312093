
import { defineComponent, toRefs, ref, computed } from "vue";
import { someColors } from "@/utils";

export default defineComponent({
  props: {
    url: String,
    title: {
      type: String,
      required: true,
    },
    deepLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { deepLink, title } = toRefs(props);
    const timer = ref<any>(null);
    const flag = ref(1);

    const iconText = computed(() => {
      const i = 0;
      let res = title.value[i].match(/[A-Za-z0-9\u4E00-\u9FA5]/);
      return res ? res[0] : "OA";
    });
    const colorIndex = computed<number>(() => {
      const titleToCharAt = iconText.value?.charCodeAt(0).toString(4);
      return parseInt(titleToCharAt.charAt(titleToCharAt.length - 1));
    });

    const touchstart = () => {
      flag.value = 1;
    };
    const touchmove = () => {
      flag.value = 0;
    };

    const touchend = () => {
      if (flag.value === 1) {
        clearTimeout(timer.value);
        timer.value = setTimeout(() => {
          window.location.href = deepLink.value;
        }, 200);
      }
    };
    return {
      touchstart,
      touchmove,
      touchend,
      someColors,
      colorIndex,
      iconText,
    };
  },
});
