
import { defineComponent, toRefs } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MainCard",
  props: {
    title: String,
    icon: String,
    num: Number,
    to: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const { to } = toRefs(props);
    const touchend = () => {
      if (typeof to.value === "string") {
        if (to.value.indexOf("jdme://") > -1) {
          window.location.href = to.value;
        } else {
          router.push(to.value);
        }
      }
    };
    return { touchend };
  },
});
